<template>
  <div
    v-if="authStore.isImpersonating"
    class="fixed bottom-0 left-0 w-full text-xs right-0 bg-black text-white py-2 px-4 flex justify-between items-center z-50"
  >
    <div class="flex items-center">
      <span class="text-yellow-400 mr-2"> </span>
      <span> Du bruger systemet som: {{ authStore.user?.name }} </span>
    </div>
    <button
      @click="stopImpersonating"
      class="bg-red-600 hover:bg-red-700 text-white px-4 py-1 rounded-md text-xs"
    >
      Afslut
    </button>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/store/auth";
import { useRouter } from "vue-router";

const authStore = useAuthStore();
const router = useRouter();

const stopImpersonating = async () => {
  await authStore.stopImpersonating();
  router.push("/"); // Eller hvor du ønsker at omdirigere efter afslutning af midlertidig adgang
};
</script>
