<template>
  <TransitionRoot
    :show="open"
    as="template"
    @after-leave="closeTrackTrace"
    appear
  >
    <Dialog class="relative z-10" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"
        />
      </TransitionChild>

      <div
        class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          >
            <Combobox @update:modelValue="onSelect">
              <div class="relative">
                <MagnifyingGlassIcon
                  class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <ComboboxInput
                  class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Ange Track & Trace..."
                  @change="query = $event.target.value"
                  @blur="query = ''"
                />
              </div>

              <ComboboxOptions
                v-if="filteredTrackAndTrace.length > 0"
                static
                class="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
              >
                <ComboboxOption
                  v-for="trackTrace in filteredTrackAndTrace"
                  :key="trackTrace.id"
                  :value="trackTrace"
                  as="template"
                  v-slot="{ active }"
                >
                  <li
                    :class="[
                      'cursor-default select-none px-4 py-2',
                      active && 'bg-indigo-600 text-white',
                    ]"
                  >
                    {{ trackTrace.name }}
                  </li>
                </ComboboxOption>
              </ComboboxOptions>

              <p
                v-if="query !== '' && filteredTrackAndTrace.length === 0"
                class="p-4 text-sm text-gray-500"
              >
                Inga resultat hittades.
              </p>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed, ref } from "vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

/**
 * An array containing trace objects used for tracking.
 *
 * TODO: Replace with actual data from API.
 *
 * @type {Array<Object>}
 * @property {number} id - The unique identifier of the trace.
 * @property {string} name - The name of the trace.
 * @property {string} url - The URL associated with the trace.
 */
const trackTraceObjects = [
  { id: 1, name: "#297482932198312903", url: "#" },
  { id: 1, name: "#902830202023838181", url: "#" },
  { id: 1, name: "#989322372381112212", url: "#" },
  { id: 1, name: "#123102999329023010", url: "#" },
];
const emits = defineEmits(["close"]);

function closeTrackTrace() {
  query.value = "";
  open.value = false;
  emits("close");
}

const open = ref(true);
const query = ref("");
const filteredTrackAndTrace = computed(() =>
  query.value === ""
    ? []
    : trackTraceObjects.filter((trackTrace) => {
        return trackTrace.name
          .toLowerCase()
          .includes(query.value.toLowerCase());
      }),
);

function onSelect(trackTrace) {
  // Redirect or show modal
}
</script>
