import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Transition as _Transition, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "bg-gray-100" }
const _hoisted_2 = { class: "bg-teal-800 pb-20" }
const _hoisted_3 = { class: "mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8" }
const _hoisted_4 = { class: "relative flex items-center justify-center py-5 lg:justify-between" }
const _hoisted_5 = { class: "hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5" }
const _hoisted_6 = { class: "relative ml-4 flex-shrink-0" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8" }
const _hoisted_10 = { class: "hidden border-t border-white border-opacity-20 py-5 lg:block" }
const _hoisted_11 = { class: "grid grid-cols-3 items-center gap-8" }
const _hoisted_12 = { class: "col-span-2" }
const _hoisted_13 = { class: "flex space-x-6" }
const _hoisted_14 = { class: "-mt-20 pb-8" }
const _hoisted_15 = { class: "mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8" }
const _hoisted_16 = {
  class: "bg-white",
  "aria-labelledby": "footer-heading"
}
const _hoisted_17 = { class: "mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32" }
const _hoisted_18 = { class: "xl:grid xl:grid-cols-3 xl:gap-8" }
const _hoisted_19 = { class: "mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0" }
const _hoisted_20 = { class: "md:grid md:grid-cols-2 md:gap-8" }
const _hoisted_21 = {
  role: "list",
  class: "mt-6 space-y-4"
}
const _hoisted_22 = ["href"]
const _hoisted_23 = { class: "mt-10 md:mt-0" }
const _hoisted_24 = {
  role: "list",
  class: "mt-6 space-y-4"
}
const _hoisted_25 = ["href"]
const _hoisted_26 = { class: "md:grid md:grid-cols-2 md:gap-8" }
const _hoisted_27 = {
  role: "list",
  class: "mt-6 space-y-4"
}
const _hoisted_28 = ["href"]
const _hoisted_29 = { class: "mt-10 md:mt-0" }
const _hoisted_30 = {
  role: "list",
  class: "mt-6 space-y-4"
}
const _hoisted_31 = ["href"]
const _hoisted_32 = { class: "mt-8 border-t border-gray-900/10 pt-8 md:flex md:items-center md:justify-between" }
const _hoisted_33 = { class: "flex space-x-6 md:order-2" }
const _hoisted_34 = ["href"]
const _hoisted_35 = { class: "sr-only" }
const _hoisted_36 = { class: "fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none" }
const _hoisted_37 = { class: "w-full max-w-sm" }
const _hoisted_38 = { class: "px-4 py-2 -mx-3" }
const _hoisted_39 = { class: "mx-3" }
const _hoisted_40 = { class: "font-semibold text-sm text-green-700" }
const _hoisted_41 = { class: "text-sm text-gray-600" }
const _hoisted_42 = { class: "fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none" }
const _hoisted_43 = { class: "w-full max-w-sm" }
const _hoisted_44 = { class: "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5" }
const _hoisted_45 = { class: "p-4" }
const _hoisted_46 = { class: "flex items-start" }
const _hoisted_47 = { class: "flex-shrink-0" }
const _hoisted_48 = { class: "ml-3 w-0 flex-1 pt-0.5" }
const _hoisted_49 = { class: "text-sm font-medium text-gray-900" }
const _hoisted_50 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_51 = { class: "mt-3 flex space-x-7" }

import { MenuItem as NavMenuItem } from "@/interfaces/layout.types";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import TrackTrace from "@/components/TrackTrace.vue";
import { computed, ref } from "vue";
import { defineComponent, h } from "vue";
import { CheckCircleIcon } from "@heroicons/vue/24/outline";
import { useAuthStore } from "@/store/auth";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthenticatedLayout',
  setup(__props) {

const authStore = useAuthStore();
const navigation = {
  solutions: [
    { name: "Nyheder", href: "/news" },
    { name: "Side 2", href: "#" },
    { name: "Side 3", href: "#" },
    { name: "Side 4", href: "#" },
  ],
  support: [
    { name: "Side 1", href: "#" },
    { name: "Side 2", href: "#" },
    { name: "Side 3", href: "#" },
    { name: "Side 4", href: "#" },
  ],
  company: [
    { name: "Side 1", href: "#" },
    { name: "Side 2", href: "#" },
    { name: "Side 3", href: "#" },
    { name: "Side 4", href: "#" },
  ],
  legal: [
    { name: "Side 1", href: "#" },
    { name: "Side 2", href: "#" },
    { name: "Side 3", href: "#" },
    { name: "Side 4", href: "#" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/Traeinformation/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/traeinformation/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
  ],
};

/**
 * Represents the user object.
 *
 * TODO: Implement the user object from API if available.
 */
const user = {
  name: "Tobias Blom",
  email: "tobias@blom.se",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

/**
 * Represents the user navigation menu items.
 */
const userNavigation: NavMenuItem[] = [
  {
    name: "huvudsida",
    title: "Indstillinger",
    route: "/my-account",
    roles: ["user"],
  },
  {
    name: "huvudsida",
    title: "Log ud",
    route: "/logout",
    roles: ["user"],
  },
];

/**
 * Represents the track & trace visibility
 */
const trackTraceVisible = ref(false);
const toggleTrackTrace = () => {
  trackTraceVisible.value = !trackTraceVisible.value;
};

/**
 * Represents the main navigation menu items.
 */
/**
 * Represents the main navigation menu items.
 */
const allMenuItems: NavMenuItem[] = [
  { name: "huvudsida", title: "Oversigt", route: "/", roles: ["all"] },
  { name: "sok", title: "Webshop", route: "/webshop", roles: ["all"] },
  { name: "cart", title: "Indkøbskurv", route: "/cart", roles: ["all"] },
  { name: "sok", title: "Kurser", route: "/courses", roles: ["all"] },
  {
    name: "bestall",
    title: "Abonnementer",
    route: "/subscriptions",
    roles: ["all"],
  },
  {
    name: "orders",
    title: "Ordrer",
    route: "/orders",
    roles: ["all"],
  },
  {
    name: "branches",
    title: "Filialer",
    route: "/branches",
    roles: ["organization"],
  },
  {
    name: "anvandare",
    title: "Brugere",
    route: "/accounts",
    roles: ["organization"],
  },
];

const menuItems = computed(() => {
  const user = authStore.user;
  const isPrivateUser =
    user?.is_student === 1 || user?.organization_id === null;

  return allMenuItems.filter(
    (item) =>
      item.roles.includes("all") ||
      (!isPrivateUser && item.roles.includes("organization")),
  );
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Notification = _resolveComponent("Notification")!
  const _component_NotificationGroup = _resolveComponent("NotificationGroup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (trackTraceVisible.value)
        ? (_openBlock(), _createBlock(TrackTrace, {
            key: 0,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (trackTraceVisible.value = false))
          }))
        : _createCommentVNode("", true),
      _createElementVNode("header", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "absolute left-0 flex-shrink-0 lg:static py-1" }, [
              _createElementVNode("a", { href: "#" }, [
                _createElementVNode("span", { class: "sr-only" }, "Your Company"),
                _createElementVNode("img", {
                  class: "h-8 w-auto mx-auto",
                  src: "https://traeinfo.dk//uploads/2018/06/traeinfo-logo-2018.svg",
                  alt: "Træinfo"
                })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", null, [
                  _createVNode(_unref(Menu), {
                    as: "div",
                    class: "relative ml-3"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_unref(MenuButton), { class: "relative flex rounded-full bg-white text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100" }, {
                          default: _withCtx(() => [
                            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "absolute -inset-1.5" }, null, -1)),
                            _createElementVNode("img", {
                              class: "h-8 w-8 rounded-full",
                              src: user.imageUrl,
                              alt: ""
                            }, null, 8, _hoisted_7)
                          ]),
                          _: 1
                        })
                      ]),
                      _createVNode(_Transition, {
                        "enter-active-class": "transition ease-out duration-200",
                        "enter-from-class": "transform opacity-0 scale-95",
                        "enter-to-class": "transform opacity-100 scale-100",
                        "leave-active-class": "transition ease-in duration-75",
                        "leave-from-class": "transform opacity-100 scale-100",
                        "leave-to-class": "transform opacity-0 scale-95"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(MenuItems), { class: "absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(userNavigation, (item) => {
                                return _createVNode(_unref(MenuItem), {
                                  key: item.name
                                }, {
                                  default: _withCtx(({ active }) => [
                                    _createElementVNode("a", {
                                      href: item.route,
                                      class: _normalizeClass([
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700',
                          ])
                                    }, _toDisplayString(item.title), 11, _hoisted_8)
                                  ]),
                                  _: 2
                                }, 1024)
                              }), 64))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("nav", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItems.value, (item) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: item.name
                    }, [
                      (item.name !== 'tracktrace')
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: item.route,
                            class: _normalizeClass([
                      'text-teal-50 rounded-md bg-white bg-opacity-0 px-3 py-2 font-medium hover:bg-opacity-10',
                      {
                        'opacity-21': item.route.length === 0,
                        'text-white': _ctx.$route.path === item.route,
                      },
                    ]),
                            "exact-active-class": "text-white"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.title), 1)
                            ]),
                            _: 2
                          }, 1032, ["to", "class"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("main", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _createElementVNode("footer", _hoisted_16, [
        _cache[10] || (_cache[10] = _createElementVNode("h2", {
          id: "footer-heading",
          class: "sr-only"
        }, "Footer", -1)),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _cache[7] || (_cache[7] = _createElementVNode("img", {
              class: "h-8 w-auto",
              src: "https://traeinfo.dk//uploads/2018/06/traeinfo-logo-2018.svg",
              alt: "Træinfo"
            }, null, -1)),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", null, [
                  _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "text-sm font-semibold leading-6 text-gray-900" }, " Genveje ", -1)),
                  _createElementVNode("ul", _hoisted_21, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navigation.solutions, (item) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: item.name
                      }, [
                        _createElementVNode("a", {
                          href: item.href,
                          class: "text-sm leading-6 text-gray-600 hover:text-gray-900"
                        }, _toDisplayString(item.name), 9, _hoisted_22)
                      ]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "text-sm font-semibold leading-6 text-gray-900" }, " Abonnementer ", -1)),
                  _createElementVNode("ul", _hoisted_24, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navigation.support, (item) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: item.name
                      }, [
                        _createElementVNode("a", {
                          href: item.href,
                          class: "text-sm leading-6 text-gray-600 hover:text-gray-900"
                        }, _toDisplayString(item.name), 9, _hoisted_25)
                      ]))
                    }), 128))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", null, [
                  _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-sm font-semibold leading-6 text-gray-900" }, " Produkter ", -1)),
                  _createElementVNode("ul", _hoisted_27, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navigation.company, (item) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: item.name
                      }, [
                        _createElementVNode("a", {
                          href: item.href,
                          class: "text-sm leading-6 text-gray-600 hover:text-gray-900"
                        }, _toDisplayString(item.name), 9, _hoisted_28)
                      ]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "text-sm font-semibold leading-6 text-gray-900" }, " Vores sider ", -1)),
                  _createElementVNode("ul", _hoisted_30, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navigation.legal, (item) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: item.name
                      }, [
                        _createElementVNode("a", {
                          href: item.href,
                          class: "text-sm leading-6 text-gray-600 hover:text-gray-900"
                        }, _toDisplayString(item.name), 9, _hoisted_31)
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ])
          ]),
          _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between\"><div><h3 class=\"text-sm font-semibold leading-6 text-gray-900\"> Tilmeld dig vores nyhedsbrev </h3><p class=\"mt-2 text-sm leading-6 text-gray-600\"> Seneste bøger, kurser og anden relevant information fra Træinformation. </p></div><form class=\"mt-6 sm:flex sm:max-w-md lg:mt-0\"><label for=\"email-address\" class=\"sr-only\">Email address</label><input type=\"email\" name=\"email-address\" id=\"email-address\" autocomplete=\"email\" required=\"\" class=\"w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:w-56 sm:text-sm sm:leading-6\" placeholder=\"Din e-mail\"><div class=\"mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0\"><button type=\"submit\" class=\"flex w-full items-center justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600\"> Tilmeld </button></div></form></div>", 1)),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navigation.social, (item) => {
                return (_openBlock(), _createElementBlock("a", {
                  key: item.name,
                  href: item.href,
                  class: "text-gray-400 hover:text-gray-500"
                }, [
                  _createElementVNode("span", _hoisted_35, _toDisplayString(item.name), 1),
                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  }))
                ], 8, _hoisted_34))
              }), 128))
            ]),
            _cache[8] || (_cache[8] = _createElementVNode("p", { class: "mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0" }, " © 2024 Træinformation. ", -1))
          ])
        ])
      ])
    ]),
    _createVNode(_component_NotificationGroup, { group: "foo" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_36, [
          _createElementVNode("div", _hoisted_37, [
            _createVNode(_component_Notification, {
              enter: "transform ease-out duration-300 transition",
              "enter-from": "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4",
              "enter-to": "translate-y-0 opacity-100 sm:translate-x-0",
              leave: "transition ease-in duration-500",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0",
              move: "transition duration-500",
              "move-delay": "delay-300"
            }, {
              default: _withCtx(({ notifications }) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notifications, (notification) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md",
                    key: notification.id
                  }, [
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex items-center justify-center w-12 bg-green-600" }, [
                      _createElementVNode("svg", {
                        class: "w-6 h-6 text-white fill-current",
                        viewBox: "0 0 40 40",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", { d: "M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" })
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("div", _hoisted_39, [
                        _createElementVNode("span", _hoisted_40, _toDisplayString(notification.title), 1),
                        _createElementVNode("p", _hoisted_41, _toDisplayString(notification.text), 1)
                      ])
                    ])
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_NotificationGroup, { group: "cart" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_42, [
          _createElementVNode("div", _hoisted_43, [
            _createVNode(_component_Notification, {
              enter: "transform ease-out duration-300 transition",
              "enter-from": "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4",
              "enter-to": "translate-y-0 opacity-100 sm:translate-x-0",
              leave: "transition ease-in duration-500",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0",
              move: "transition duration-500",
              "move-delay": "delay-300"
            }, {
              default: _withCtx(({ notifications }) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notifications, (notification) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "flex w-full flex-col items-center space-y-4 sm:items-end mb-2",
                    key: notification.id
                  }, [
                    _createVNode(_Transition, {
                      "enter-active-class": "transform ease-out duration-300 transition",
                      "enter-from-class": "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
                      "enter-to-class": "translate-y-0 opacity-100 sm:translate-x-0",
                      "leave-active-class": "transition ease-in duration-100",
                      "leave-from-class": "opacity-100",
                      "leave-to-class": "opacity-0"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_44, [
                          _createElementVNode("div", _hoisted_45, [
                            _createElementVNode("div", _hoisted_46, [
                              _createElementVNode("div", _hoisted_47, [
                                _createVNode(_unref(CheckCircleIcon), {
                                  class: "h-6 w-6 text-green-400",
                                  "aria-hidden": "true"
                                })
                              ]),
                              _createElementVNode("div", _hoisted_48, [
                                _createElementVNode("p", _hoisted_49, _toDisplayString(notification.title), 1),
                                _createElementVNode("p", _hoisted_50, _toDisplayString(notification.text), 1),
                                _createElementVNode("div", _hoisted_51, [
                                  _createVNode(_component_router_link, {
                                    to: "/cart",
                                    class: "inline-block"
                                  }, {
                                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                                      _createElementVNode("button", {
                                        type: "button",
                                        class: "rounded-md bg-white text-sm font-medium text-teal-600 hover:text-teal-500 focus:outline-none"
                                      }, " Vis indkøbskurv ", -1)
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_router_link, {
                                    to: "/cart/checkout",
                                    class: "inline-block"
                                  }, {
                                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                                      _createElementVNode("button", {
                                        type: "button",
                                        class: "rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none"
                                      }, " Til kassen ", -1)
                                    ])),
                                    _: 1
                                  })
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}
}

})