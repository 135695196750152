import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./assets/styles/index.scss";
import Vue3EasyDataTable from "vue3-easy-data-table";
import ToastPlugin from "vue-toast-notification";
import { createPinia } from "pinia";
import { useAuthStore } from "@/store/auth";
import piniaPersist from "pinia-plugin-persist";
import Notifications from "notiwind";

const pinia = createPinia();
pinia.use(piniaPersist);
const app = createApp(App).use(router).use(pinia);
const auth = useAuthStore();

app.component("EasyDataTable", Vue3EasyDataTable);

app.use(Notifications);

auth.initializeStore();
app.mount("#app");
