import { defineStore } from "pinia";
import { Order, OrderSearchRequest } from "@/interfaces/order.types";
import { getOrder, listOrders, searchOrders } from "@/services/api/orders";

interface OrderState {
  orders: Order[];
  loading: boolean;
  selectedOrder: Order | null;
}

export const useOrderStore = defineStore("order", {
  state: (): OrderState => ({
    orders: [],
    loading: false,
    selectedOrder: null,
  }),
  getters: {
    orderCount(state): number {
      return state.orders.length;
    },
  },
  actions: {
    setLoading(isLoading: boolean) {
      this.loading = isLoading;
    },
    clear() {
      this.orders = [];
      this.selectedOrder = null;
    },
    async fetchOrders() {
      this.setLoading(true);
      try {
        this.orders = await listOrders();
      } catch (error) {
        console.error("Failed to fetch orders:", error);
      } finally {
        this.setLoading(false);
      }
    },

    async searchOrders(orderSearchRequest: OrderSearchRequest) {
      this.setLoading(true);
      try {
        this.orders = await searchOrders(orderSearchRequest);
      } catch (error) {
        console.error("Failed to fetch orders:", error);
      } finally {
        this.setLoading(false);
      }
    },
    async fetchOrderById(orderId: number) {
      try {
        this.selectedOrder = await getOrder(orderId);
      } catch (error) {
        console.error("Failed to fetch order:", error);
      }
    },
    clearSelectedOrder() {
      this.selectedOrder = null;
    },
  },
});
