import { apiClient } from "@/services/api";
import { Invoice } from "@/interfaces/invoice.types";

export interface ApiResponse<T> {
  data: T;
}

const listInvoices = (): Promise<Invoice[]> => {
  return apiClient
    .get<ApiResponse<Invoice[]>>("api/organization/invoices")
    .then((response) => response.data.data);
};
/**
 * Get a specific order by ID.
 *
 * @param {number} id - The ID of the order to retrieve.
 * @returns {Promise<Order>} A promise that resolves to the retrieved order.
 */
const getInvoice = (id: number): Promise<Invoice> => {
  return apiClient
    .get<Invoice>("invoices/${id}")
    .then((response) => response.data);
};

export { getInvoice, listInvoices };
