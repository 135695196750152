import { defineStore } from "pinia";
import {
  listAccounts,
  getAccount,
  createAccount,
  updateAccount,
  getRoles,
  deleteAccount,
  listAllAccounts,
} from "@/services/api/accounts";
import { Account } from "@/interfaces/accounts.types";
import { Role } from "@/interfaces/auth.types";
interface AccountState {
  accounts: Account[];
  allAccounts: Account[];
  loading: boolean;
  roles: Role[];
  selectedAccount: Account | null;
}

export const useAccountStore = defineStore("account", {
  state: (): AccountState => ({
    accounts: [],
    allAccounts: [],
    roles: [],
    loading: false,
    selectedAccount: null,
  }),
  getters: {
    accountCount(state): number {
      return state.accounts.length;
    },
  },
  actions: {
    clear() {
      this.accounts = [];
    },
    clearSelectedAccount() {
      this.selectedAccount = null;
    },
    async deleteAccount(id: number) {
      this.loading = true;
      try {
        const response = await deleteAccount(id);
        this.accounts = this.accounts.filter((account) => account.id !== id);
        return response;
      } catch (error: unknown) {
        console.error("Failed to delete account:", error);
        if (error instanceof Error) {
          throw error;
        } else {
          throw new Error(
            "An unknown error occurred while deleting the account",
          );
        }
      } finally {
        this.setLoading(false);
      }
    },
    async createAccount(account: Account | Omit<Account, "id">) {
      this.loading = true;
      try {
        await createAccount(account);
      } catch (error) {
        console.error("Failed to fetch accounts:", error);
        throw new Error("Användaren kunde inte sparas. Försök igen senare.");
      } finally {
        this.setLoading(false);
      }
    },
    async fetchAccountById(id: number) {
      try {
        this.selectedAccount = await getAccount(id);
      } catch (error) {
        console.error("Failed to fetch account:", error);
      }
    },
    async fetchRoles() {
      this.setLoading(true);
      try {
        this.roles = await getRoles();
      } catch (error) {
        console.error("Failed to fetch roles:", error);
      } finally {
        this.setLoading(false);
      }
    },
    async fetchAccounts() {
      this.setLoading(true);
      try {
        this.accounts = await listAccounts();
      } catch (error) {
        console.error("Failed to fetch accounts:", error);
      } finally {
        this.setLoading(false);
      }
    },
    setLoading(isLoading: boolean) {
      this.loading = isLoading;
    },
    async fetchAllAccounts() {
      this.setLoading(true);
      try {
        this.allAccounts = await listAllAccounts();
      } catch (error) {
        console.error("Failed to fetch accounts:", error);
      } finally {
        this.setLoading(false);
      }
    },
    async updateAccount(account: Account) {
      this.loading = true;
      try {
        await updateAccount(account);
      } catch (error) {
        console.error("Failed to fetch accounts:", error);
        throw new Error(
          "Användaren kunde inte uppdateras. Försök igen senare.",
        );
      } finally {
        this.setLoading(false);
      }
    },
  },
});
