<template>
  <div class="bg-gray-100">
    <track-trace v-if="trackTraceVisible" @close="trackTraceVisible = false" />

    <header class="bg-teal-800 pb-20">
      <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div
          class="relative flex items-center justify-center py-5 lg:justify-between"
        >
          <!-- Logo -->
          <div class="absolute left-0 flex-shrink-0 lg:static py-1">
            <a href="#">
              <span class="sr-only">Your Company</span>
              <img
                class="h-8 w-auto mx-auto"
                src="https://traeinfo.dk//uploads/2018/06/traeinfo-logo-2018.svg"
                alt="Træinfo"
              />
            </a>
          </div>

          <div class="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5">
            <!-- Profile dropdown -->
            <div class="relative ml-4 flex-shrink-0">
              <div>
                <Menu as="div" class="relative ml-3">
                  <div>
                    <MenuButton
                      class="relative flex rounded-full bg-white text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100"
                    >
                      <span class="absolute -inset-1.5" />
                      <img
                        class="h-8 w-8 rounded-full"
                        :src="user.imageUrl"
                        alt=""
                      />
                    </MenuButton>
                  </div>
                  <transition
                    enter-active-class="transition ease-out duration-200"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <MenuItem
                        v-for="item in userNavigation"
                        :key="item.name"
                        v-slot="{ active }"
                      >
                        <a
                          :href="item.route"
                          :class="[
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700',
                          ]"
                          >{{ item.title }}</a
                        >
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div
          class="hidden border-t border-white border-opacity-20 py-5 lg:block"
        >
          <div class="grid grid-cols-3 items-center gap-8">
            <div class="col-span-2">
              <nav class="flex space-x-6">
                <template v-for="item in menuItems" :key="item.name">
                  <router-link
                    v-if="item.name !== 'tracktrace'"
                    :to="item.route"
                    :class="[
                      'text-teal-50 rounded-md bg-white bg-opacity-0 px-3 py-2 font-medium hover:bg-opacity-10',
                      {
                        'opacity-21': item.route.length === 0,
                        'text-white': $route.path === item.route,
                      },
                    ]"
                    exact-active-class="text-white"
                  >
                    {{ item.title }}
                  </router-link>
                </template>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>

    <main class="-mt-20 pb-8">
      <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <slot />
      </div>
    </main>
    <footer class="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div class="xl:grid xl:grid-cols-3 xl:gap-8">
          <img
            class="h-8 w-auto"
            src="https://traeinfo.dk//uploads/2018/06/traeinfo-logo-2018.svg"
            alt="Træinfo"
          />
          <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 class="text-sm font-semibold leading-6 text-gray-900">
                  Genveje
                </h3>
                <ul role="list" class="mt-6 space-y-4">
                  <li v-for="item in navigation.solutions" :key="item.name">
                    <a
                      :href="item.href"
                      class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                      >{{ item.name }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="mt-10 md:mt-0">
                <h3 class="text-sm font-semibold leading-6 text-gray-900">
                  Abonnementer
                </h3>
                <ul role="list" class="mt-6 space-y-4">
                  <li v-for="item in navigation.support" :key="item.name">
                    <a
                      :href="item.href"
                      class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                      >{{ item.name }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 class="text-sm font-semibold leading-6 text-gray-900">
                  Produkter
                </h3>
                <ul role="list" class="mt-6 space-y-4">
                  <li v-for="item in navigation.company" :key="item.name">
                    <a
                      :href="item.href"
                      class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                      >{{ item.name }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="mt-10 md:mt-0">
                <h3 class="text-sm font-semibold leading-6 text-gray-900">
                  Vores sider
                </h3>
                <ul role="list" class="mt-6 space-y-4">
                  <li v-for="item in navigation.legal" :key="item.name">
                    <a
                      :href="item.href"
                      class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                      >{{ item.name }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between"
        >
          <div>
            <h3 class="text-sm font-semibold leading-6 text-gray-900">
              Tilmeld dig vores nyhedsbrev
            </h3>
            <p class="mt-2 text-sm leading-6 text-gray-600">
              Seneste bøger, kurser og anden relevant information fra
              Træinformation.
            </p>
          </div>
          <form class="mt-6 sm:flex sm:max-w-md lg:mt-0">
            <label for="email-address" class="sr-only">Email address</label>
            <input
              type="email"
              name="email-address"
              id="email-address"
              autocomplete="email"
              required=""
              class="w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:w-56 sm:text-sm sm:leading-6"
              placeholder="Din e-mail"
            />
            <div class="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
              <button
                type="submit"
                class="flex w-full items-center justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Tilmeld
              </button>
            </div>
          </form>
        </div>
        <div
          class="mt-8 border-t border-gray-900/10 pt-8 md:flex md:items-center md:justify-between"
        >
          <div class="flex space-x-6 md:order-2">
            <a
              v-for="item in navigation.social"
              :key="item.name"
              :href="item.href"
              class="text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">{{ item.name }}</span>
              <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
            </a>
          </div>
          <p class="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
            &copy; 2024 Træinformation.
          </p>
        </div>
      </div>
    </footer>
  </div>
  <NotificationGroup group="foo">
    <div
      class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none"
    >
      <div class="w-full max-w-sm">
        <Notification
          v-slot="{ notifications }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
        >
          <div
            class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md"
            v-for="notification in notifications"
            :key="notification.id"
          >
            <div class="flex items-center justify-center w-12 bg-green-600">
              <svg
                class="w-6 h-6 text-white fill-current"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
                />
              </svg>
            </div>

            <div class="px-4 py-2 -mx-3">
              <div class="mx-3">
                <span class="font-semibold text-sm text-green-700">{{
                  notification.title
                }}</span>
                <p class="text-sm text-gray-600">{{ notification.text }}</p>
              </div>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
  <NotificationGroup group="cart">
    <div
      class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none"
    >
      <div class="w-full max-w-sm">
        <Notification
          v-slot="{ notifications }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
        >
          <div
            class="flex w-full flex-col items-center space-y-4 sm:items-end mb-2"
            v-for="notification in notifications"
            :key="notification.id"
          >
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition
              enter-active-class="transform ease-out duration-300 transition"
              enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
              leave-active-class="transition ease-in duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div
                class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
              >
                <div class="p-4">
                  <div class="flex items-start">
                    <div class="flex-shrink-0">
                      <CheckCircleIcon
                        class="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ml-3 w-0 flex-1 pt-0.5">
                      <p class="text-sm font-medium text-gray-900">
                        {{ notification.title }}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                        {{ notification.text }}
                      </p>
                      <div class="mt-3 flex space-x-7">
                        <router-link to="/cart" class="inline-block">
                          <button
                            type="button"
                            class="rounded-md bg-white text-sm font-medium text-teal-600 hover:text-teal-500 focus:outline-none"
                          >
                            Vis indkøbskurv
                          </button>
                        </router-link>
                        <router-link to="/cart/checkout" class="inline-block">
                          <button
                            type="button"
                            class="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none"
                          >
                            Til kassen
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
</template>

<script setup lang="ts">
import { MenuItem as NavMenuItem } from "@/interfaces/layout.types";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import TrackTrace from "@/components/TrackTrace.vue";
import { computed, ref } from "vue";
import { defineComponent, h } from "vue";
import { CheckCircleIcon } from "@heroicons/vue/24/outline";
import { useAuthStore } from "@/store/auth";

const authStore = useAuthStore();
const navigation = {
  solutions: [
    { name: "Nyheder", href: "/news" },
    { name: "Side 2", href: "#" },
    { name: "Side 3", href: "#" },
    { name: "Side 4", href: "#" },
  ],
  support: [
    { name: "Side 1", href: "#" },
    { name: "Side 2", href: "#" },
    { name: "Side 3", href: "#" },
    { name: "Side 4", href: "#" },
  ],
  company: [
    { name: "Side 1", href: "#" },
    { name: "Side 2", href: "#" },
    { name: "Side 3", href: "#" },
    { name: "Side 4", href: "#" },
  ],
  legal: [
    { name: "Side 1", href: "#" },
    { name: "Side 2", href: "#" },
    { name: "Side 3", href: "#" },
    { name: "Side 4", href: "#" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/Traeinformation/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/traeinformation/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
  ],
};

/**
 * Represents the user object.
 *
 * TODO: Implement the user object from API if available.
 */
const user = {
  name: "Tobias Blom",
  email: "tobias@blom.se",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

/**
 * Represents the user navigation menu items.
 */
const userNavigation: NavMenuItem[] = [
  {
    name: "huvudsida",
    title: "Indstillinger",
    route: "/my-account",
    roles: ["user"],
  },
  {
    name: "huvudsida",
    title: "Log ud",
    route: "/logout",
    roles: ["user"],
  },
];

/**
 * Represents the track & trace visibility
 */
const trackTraceVisible = ref(false);
const toggleTrackTrace = () => {
  trackTraceVisible.value = !trackTraceVisible.value;
};

/**
 * Represents the main navigation menu items.
 */
/**
 * Represents the main navigation menu items.
 */
const allMenuItems: NavMenuItem[] = [
  { name: "huvudsida", title: "Oversigt", route: "/", roles: ["all"] },
  { name: "sok", title: "Webshop", route: "/webshop", roles: ["all"] },
  { name: "cart", title: "Indkøbskurv", route: "/cart", roles: ["all"] },
  { name: "sok", title: "Kurser", route: "/courses", roles: ["all"] },
  {
    name: "bestall",
    title: "Abonnementer",
    route: "/subscriptions",
    roles: ["all"],
  },
  {
    name: "orders",
    title: "Ordrer",
    route: "/orders",
    roles: ["all"],
  },
  {
    name: "branches",
    title: "Filialer",
    route: "/branches",
    roles: ["organization"],
  },
  {
    name: "anvandare",
    title: "Brugere",
    route: "/accounts",
    roles: ["organization"],
  },
];

const menuItems = computed(() => {
  const user = authStore.user;
  const isPrivateUser =
    user?.is_student === 1 || user?.organization_id === null;

  return allMenuItems.filter(
    (item) =>
      item.roles.includes("all") ||
      (!isPrivateUser && item.roles.includes("organization")),
  );
});
</script>
