import { defineStore } from "pinia";
import {
  fetchUser,
  login,
  register,
  impersonateUser as impersonateUserApi,
  stopImpersonating as stopImpersonatingApi,
} from "@/services/api/user";
import { useOrderStore } from "@/store/orders";
import { useAccountStore } from "@/store/accounts";
import { useCourseStore } from "@/store/courses";
import { useInvoiceStore } from "@/store/invoices";
import type {
  AuthState,
  User,
  ApiResponse,
  RegisterUserData,
  ApiError,
  Role,
  Permission,
} from "@/interfaces/auth.types";

interface LoginResult {
  error?: string;
  success?: boolean;
}

interface RegisterResult {
  error?: string;
  success?: boolean;
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    accessToken: null,
    user: null,
    organization: null,
    memberships: [],
    access: [],
    isImpersonating: false,
    originalToken: null,
    impersonatedUser: null,
  }),

  getters: {
    isAuthenticated(): boolean {
      return !!this.accessToken;
    },

    me(): User | null {
      return this.user;
    },

    userPermissions(): Set<string> {
      const permissions = new Set<string>();

      this.user?.roles?.forEach((role: Role) => {
        role.permissions?.forEach((permission: Permission) => {
          permissions.add(permission.name);
        });
      });

      return permissions;
    },
  },

  actions: {
    async impersonateUser(userId: string): Promise<string | undefined> {
      try {
        const response = await impersonateUserApi(userId);
        if (response.token) {
          this.originalToken = this.accessToken;
          this.setAccessToken(response.token);
          this.isImpersonating = true;

          const userData = await fetchUser();
          this.updateStateWithUserData(userData);

          return response.token;
        }
      } catch (error) {
        console.error("Failed to impersonate user:", error);
        throw error;
      }
    },

    async stopImpersonating(): Promise<void> {
      try {
        await stopImpersonatingApi();
        if (this.originalToken) {
          this.setAccessToken(this.originalToken);
          this.originalToken = null;
          this.isImpersonating = false;

          const userData = await fetchUser();
          this.updateStateWithUserData(userData);
        }
      } catch (error) {
        console.error("Failed to stop impersonating:", error);
        throw error;
      }
    },

    async updateUser(): Promise<void> {
      try {
        const response = await fetchUser();
        if (response) {
          this.updateStateWithUserData(response);
        }
      } catch (error) {
        console.error("Failed to fetch user:", error);
        throw error;
      }
    },

    async login(email: string, password: string): Promise<LoginResult> {
      try {
        const response = await login(email, password);
        if (response) {
          this.updateStateWithUserData(response);
          this.setAccessToken(response.token);
          return { success: true };
        }
        return { error: "Uventet fejl under login." };
      } catch (error) {
        console.error("Login error:", error);
        return { error: "Forkert kombination af e-mail og kodeord." };
      }
    },

    async register(userData: RegisterUserData): Promise<RegisterResult> {
      try {
        const response = await register(userData);
        if (response) {
          this.updateStateWithUserData(response);
          this.setAccessToken(response.token);
          return { success: true };
        }
        return { error: "Uventet fejl under registrering." };
      } catch (error) {
        console.error("Registration error:", error);
        return this.handleRegistrationError(error);
      }
    },

    handleRegistrationError(error: unknown): RegisterResult {
      let errorMessage =
        "Der opstod en fejl under registrering. Prøv venligst igen.";

      if (error instanceof Error && "response" in error) {
        const apiError = (error as any).response?.data as ApiError;
        if (apiError?.errors) {
          const messages = Object.values(apiError.errors).flat();
          errorMessage = messages.join(" ");
        } else if (apiError?.message) {
          errorMessage = apiError.message;
        }
      }

      return { error: errorMessage };
    },

    updateStateWithUserData(data: ApiResponse): void {
      this.user = data.user;
      this.organization = data.organization;
      this.memberships = data.memberships || [];
      this.access = data.access || [];
    },

    logout(): void {
      this.clear();
      useOrderStore().clear();
      useAccountStore().clear();
      useCourseStore().clear();
      useInvoiceStore().clear();
    },

    clear(): void {
      this.setAccessToken("");
      this.accessToken = null;
      this.user = null;
      this.organization = null;
      this.memberships = [];
      this.access = [];
      this.isImpersonating = false;
      this.originalToken = null;
      this.impersonatedUser = null;
    },

    setAccessToken(token: string): void {
      this.accessToken = token;
      if (token) {
        localStorage.setItem("accessToken", token);
      } else {
        localStorage.removeItem("accessToken");
      }
    },

    initializeStore(): void {
      const token = localStorage.getItem("accessToken");
      if (token) {
        this.accessToken = token;
      }
    },
  },

  persist: {
    enabled: true,
  },
});
