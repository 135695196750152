import { defineStore } from "pinia";
import { Invoice } from "@/interfaces/invoice.types";
import { getInvoice, listInvoices } from "@/services/api/invoices";

interface InvoiceState {
  invoices: Invoice[];
  loading: boolean;
  selectedInvoice: Invoice | null;
}

export const useInvoiceStore = defineStore("invoice", {
  state: (): InvoiceState => ({
    invoices: [],
    loading: false,
    selectedInvoice: null,
  }),
  getters: {
    invoiceCount(state): number {
      return state.invoices.length;
    },
  },
  actions: {
    setLoading(isLoading: boolean) {
      this.loading = isLoading;
    },
    clear() {
      this.invoices = [];
      this.selectedInvoice = null;
    },
    async fetchInvoiceById(invoiceId: number) {
      try {
        this.selectedInvoice = await getInvoice(invoiceId);
      } catch (error) {
        console.error("Failed to fetch order:", error);
      }
    },
    async fetchInvoices() {
      this.setLoading(true);
      try {
        this.invoices = await listInvoices();
      } catch (error) {
        console.error("Failed to fetch invoices:", error);
      } finally {
        this.setLoading(false);
      }
    },
    clearSelectedInvoice() {
      this.selectedInvoice = null;
    },
  },
});
