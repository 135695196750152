import { defineStore } from "pinia";
import { listCourses } from "@/services/api/courses";
import { Course } from "@/interfaces/courses.types";

interface CourseState {
  courses: Course[];
  loading: boolean;
}

export const useCourseStore = defineStore("course", {
  state: (): CourseState => ({
    courses: [],
    loading: false,
  }),
  getters: {
    courseCount(state): number {
      return state.courses.length;
    },
  },
  actions: {
    async fetchCourses() {
      this.setLoading(true);
      try {
        this.courses = await listCourses();
      } catch (error) {
        console.error("Failed to fetch courses:", error);
      } finally {
        this.setLoading(false);
      }
    },

    clear() {
      this.courses = [];
    },

    setLoading(isLoading: boolean) {
      this.loading = isLoading;
    },
  },
});
