import axios from "axios";
import { useAuthStore } from "@/store/auth";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
});

apiClient.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();
    if (authStore.accessToken) {
      config.headers.Authorization = `Bearer ${authStore.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const apiWordpressWoocommerceClient = axios.create({
  baseURL: "https://traeinfo.dk/wp-json/wc/v3",
  auth: {
    username: "ck_0eeae2af879c679712651c8610b04a6315ca8abd",
    password: "cs_1ef7069f663b393caa0532b62aa110d5767f5b38",
  },
});

const apiWordpressClient = axios.create({
  baseURL: "https://traeinfo.dk/wp-json/wp/v2",
});

export { apiClient, apiWordpressClient, apiWordpressWoocommerceClient };
