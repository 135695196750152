import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_impersonation_banner = _resolveComponent("impersonation-banner")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
    default: _withCtx(() => [
      _createVNode(_component_impersonation_banner),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}