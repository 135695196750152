import { apiClient } from "@/services/api";
import { Order, OrderSearchRequest } from "@/interfaces/order.types";

/**
 * List all orders.
 *
 * @returns {Promise<Order[]>} A promise that resolves to an array of orders.
 */
const listOrders = (): Promise<Order[]> => {
  return apiClient.get<Order[]>("orders").then((response) => response.data);
};
/**
 * Searches for orders based on the specified search criteria.
 *
 * @param {OrderSearchRequest} orderSearchRequest - The search criteria for finding orders.
 * @returns {Promise<Order[]>} - A promise that resolves with an array of orders that match the search criteria.
 */
const searchOrders = (
  orderSearchRequest: OrderSearchRequest,
): Promise<Order[]> => {
  return apiClient
    .post<Order[]>("orders/search", orderSearchRequest)
    .then((response) => response.data);
};

/**
 * Create a new order.
 *
 * @param {Order} orderData - The order data to create.
 * @returns {Promise<Order>} A promise that resolves to the created order.
 */
const createOrder = (orderData: Order): Promise<Order> => {
  return apiClient
    .post<Order>("orders", orderData)
    .then((response) => response.data);
};

/**
 * Get a specific order by ID.
 *
 * @param {number} id - The ID of the order to retrieve.
 * @returns {Promise<Order>} A promise that resolves to the retrieved order.
 */
const getOrder = (id: number): Promise<Order> => {
  return apiClient.get<Order>("orders/${id}").then((response) => response.data);
};

/**
 * Fetch the Proof of Delivery (POD) for a specific order.
 *
 * @param {number} id - The ID of the order.
 * @returns {Promise<any>} A promise that resolves to the POD data.
 */
const getOrderPOD = (id: number): Promise<any> => {
  return apiClient
    .get<any>("orders/${id}/pod")
    .then((response) => response.data);
};

/**
 * Get the PDF for a specific order.
 *
 * @param {number} id - The ID of the order.
 * @returns {Promise<Blob>} A promise that resolves to the PDF data as a blob.
 */
const getOrderPDF = (id: number): Promise<Blob> => {
  return apiClient
    .get<Blob>("orders/${id}/pdf", { responseType: "blob" })
    .then((response) => response.data);
};

/**
 * Get the XLS (Excel) for a specific order.
 *
 * @param {number} id - The ID of the order.
 * @returns {Promise<Blob>} A promise that resolves to the XLS data as a blob.
 */
const getOrderXLS = (id: number): Promise<Blob> => {
  return apiClient
    .get<Blob>("orders/${id}/xls", { responseType: "blob" })
    .then((response) => response.data);
};

/**
 * Fetch the waybill for a specific order.
 *
 * @param {number} id - The ID of the order.
 * @returns {Promise<any>} A promise that resolves to the waybill data.
 */
const getOrderWaybill = (id: number): Promise<any> => {
  return apiClient
    .get<any>("orders/${id}/waybill")
    .then((response) => response.data);
};

/**
 * Fetch the logistic label for a specific order.
 *
 * @param {number} id - The ID of the order.
 * @returns {Promise<any>} A promise that resolves to the logistic label data.
 */
const getOrderLogisticLabel = (id: number): Promise<any> => {
  return apiClient
    .get<any>("orders/${id}/logisticLabel")
    .then((response) => response.data);
};

export {
  listOrders,
  createOrder,
  getOrder,
  getOrderPOD,
  getOrderPDF,
  getOrderXLS,
  getOrderWaybill,
  getOrderLogisticLabel,
  searchOrders,
};
