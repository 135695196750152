import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed bottom-0 left-0 w-full text-xs right-0 bg-black text-white py-2 px-4 flex justify-between items-center z-50"
}
const _hoisted_2 = { class: "flex items-center" }

import { useAuthStore } from "@/store/auth";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'ImpersonationBanner',
  setup(__props) {

const authStore = useAuthStore();
const router = useRouter();

const stopImpersonating = async () => {
  await authStore.stopImpersonating();
  router.push("/"); // Eller hvor du ønsker at omdirigere efter afslutning af midlertidig adgang
};

return (_ctx: any,_cache: any) => {
  return (_unref(authStore).isImpersonating)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-yellow-400 mr-2" }, null, -1)),
          _createElementVNode("span", null, " Du bruger systemet som: " + _toDisplayString(_unref(authStore).user?.name), 1)
        ]),
        _createElementVNode("button", {
          onClick: stopImpersonating,
          class: "bg-red-600 hover:bg-red-700 text-white px-4 py-1 rounded-md text-xs"
        }, " Afslut ")
      ]))
    : _createCommentVNode("", true)
}
}

})