<template>
  <div class="h-full">
    <main class="h-full">
      <slot />
    </main>
  </div>
</template>

<script lang="ts">
export default {
  name: "DefaultLayout",
};
</script>
