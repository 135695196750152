import { apiClient } from "@/services/api";
import { Account } from "@/interfaces/accounts.types";
import { Role } from "@/interfaces/auth.types";

interface CsvPreviewStats {
  totalRows: number;
  newEmails: number;
  existingEmails: number;
}

interface UploadResponse {
  message: string;
}

/**
 * Previews a CSV file and returns statistics about the users to be imported.
 *
 * @param {File} file - The CSV file to preview
 * @returns {Promise<CsvPreviewStats>} Statistics about the users in the CSV
 */
const previewCsvUsers = async (file: File): Promise<CsvPreviewStats> => {
  const formData = new FormData();
  formData.append("csv_file", file);

  return apiClient
    .post<CsvPreviewStats>("api/organization/preview-users-csv", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.data) {
        throw new Error(
          error.response.data.message ||
            error.response.data.error ||
            "Failed to preview CSV file",
        );
      }
      throw error;
    });
};

/**
 * Uploads a CSV file to import users.
 *
 * @param {File} file - The CSV file containing user data
 * @param {'add' | 'replace'} mode - Whether to add new users or replace existing ones
 * @returns {Promise<UploadResponse>} Response from the server
 */
const uploadCsvUsers = async (
  file: File,
  mode: "add" | "replace",
): Promise<UploadResponse> => {
  const formData = new FormData();
  formData.append("csv_file", file);
  formData.append("mode", mode);

  return apiClient
    .post<UploadResponse>("api/organization/upload-users-csv", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.data) {
        throw new Error(
          error.response.data.message ||
            error.response.data.error ||
            "Failed to upload CSV file",
        );
      }
      throw error;
    });
};

/**
 * List all accounts.
 *
 * @returns {Promise<Account[]>} A promise that resolves to an array of accounts.
 */
const listAccounts = (): Promise<Account[]> => {
  return apiClient
    .get<{ users: Account[] }>("api/organization/users")
    .then((response) => response.data.users);
};

const listAllAccounts = (): Promise<Account[]> => {
  return apiClient
    .get<{ users: Account[] }>("api/organization/users/all")
    .then((response) => response.data.users);
};

const deleteAccount = (id: number): Promise<void> => {
  return apiClient
    .delete(`api/organization/user/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.data) {
        throw new Error(
          error.response.data.message ||
            error.response.data.error ||
            "Failed to delete account",
        );
      }
      throw error;
    });
};

/**
 * Retrieves an account by ID.
 *
 * @param {number} id - The ID of the account to retrieve.
 * @return {Promise<Account>} - A Promise that resolves to the retrieved account.
 */
const getAccount = (id: number): Promise<Account> => {
  return apiClient
    .get<{ user: Account }>(`api/organization/user/${id}`)
    .then((response) => response.data.user);
};

const getRoles = (): Promise<Role[]> => {
  return apiClient
    .get<{ data: Role[] }>(`api/organization/roles`)
    .then((response) => response.data.data);
};

/**
 * Creates a new account.
 *
 * @param {Account | Omit<Account, "id">} account - The account object to be created.
 * @returns {Promise<Account>} A promise that resolves to the created account object.
 */
const createAccount = (
  account: Account | Omit<Account, "id">,
): Promise<Account> => {
  return apiClient
    .post<{ data: Account }>("api/organization/user", account)
    .then((response) => response.data.data);
};

/**
 * Updates an account.
 *
 * @param {Account} account - The account object to be updated.
 * @returns {Promise<Account>} - A promise that resolves to the updated account object.
 */
const updateAccount = (account: Account): Promise<Account> => {
  return apiClient
    .put<{ data: Account }>(`api/organization/user/${account.id}`, account)
    .then((response) => response.data.data);
};

export {
  listAccounts,
  listAllAccounts,
  getAccount,
  createAccount,
  updateAccount,
  getRoles,
  deleteAccount,
  previewCsvUsers,
  uploadCsvUsers,
};
